export const ErrorTypes = {
  GENERAL_ERROR: 'GENERAL_ERROR',
  HTTP_ERROR: 'HTTP_ERROR',
  URL_ERROR: 'URL_ERROR',
  FETCH_ERROR: 'FETCH_ERROR',
  NO_EVENTS: 'NO_EVENTS',
  PARSE_ERROR: 'PARSE_ERROR',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  AWS_ERROR: 'AWS_ERROR',
  VALUE_ERROR: 'VALUE_ERROR',
};
